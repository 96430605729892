
import React, { useState, useEffect, useRef  } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import { toastify } from '../../../utils/toast'
import Layout from '../component/layout'
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import ReusableDialog from '../component/reusableDialog';
import ConfirmDialog from '../component/confirmDialog'
import { addBankDetails, addDefaultAccount, addInvoiceBillingURL, 
    addPenalty, 
    addReminder, 
    addTax, 
    deletePenalty,
    deleteReminder,
    deleteTax,
    disablePenalty, 
    disableReminder,
    disableTax,
    editPenalty,
    editReminder,
    getBankDetails,
    getInvoiceBillingURL,
    getLevyTypes, 
    getPenalties, 
    getReminders, 
    getTaxes, 
    removeDefaultAccount} from '../../../utils/urls';
function Settings() {

    const navigate = useNavigate();
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [dialogType, setDialogType] = useState(null);
    const [levyTypes, setLevyTypes] = useState([]);
    const [penalties, setPenalties] = useState([]);
    const [reminders, setReminders] = useState([]);
    const [taxes, setTaxes] = useState([]);

    const [title, setTitle] = useState('');
    const [module, setModule] = useState('');
    const [type, setType] = useState('');
    const [effectDays, setEffectDays] = useState(0);
    const [percentage, setPercentage] = useState('');
    const [amount, setAmount] = useState(0);
    const [time, setTime] = useState('');
    const [day, setDay] = useState('');
    const [amountError, setAmountError] = useState('');
    const [dueDays, setDueDays] = useState('');
    const [levyNote, setLevyNote] = useState('');


    const [penaltyId, setPenaltyId] = useState(null);
    const [reminderId, setReminderId] = useState(null);
    const [taxId, setTaxId] = useState(null);

    const [editDialogVisible, setEditDialogVisible] = useState(false);
    // const [selectedPenaltyId, setSelectedPenaltyId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [confirmAction, setConfirmAction] = useState('');  // Track the current action ('delete', 'enable', 'disable')
    const [selectedDisableStatus, setSelectedDisableStatus] = useState(false);  // Track the current disable status

    const [selectedRowData, setSelectedRowData] = useState({});
    const [selectedTable, setSelectedTable] = useState(''); // 'penalties', 'reminders', 'tax'
    const [entityType, setEntityType] = useState(''); 


    const [penaltySearchTerm, setPenaltySearchTerm] = useState('');
    const [reminderSearchTerm, setReminderSearchTerm] = useState('');
    const [taxSearchTerm, setTaxSearchTerm] = useState('');


    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [branchName, setBranchName] = useState('');
    const [branchCode, setBranchCode] = useState('');
    const [bankCode, setBankCode] = useState('');


    const [bankDetails, setBankDetails] = useState([]);    

    const [bankDialogVisible, setBankDialogVisible] = useState(false);

    const editorRef = useRef(null);



    const handleEdit = (rowData, table) => {
        setSelectedRowData(rowData); // Set the currently selected row data
    
        // Use currentTable to determine which data you're working with
        if (table === 'penalties') {
            setPenaltyId(rowData._id);
            setSelectedTable(table);
            setEffectDays(rowData.effectDays);
            setAmount(rowData.amount);
        } else if (table === 'reminders') {
            setReminderId(rowData._id);
            setSelectedTable(table);
            setTime(rowData.time);
            setDay(rowData.day);
        }
    
        setEditDialogVisible(true);  // Show the dialog
    };
    
    const handleShowDialog = (type) => {
        setDialogType(type);
        setVisibleDialog(true);
    };

    const handleHideDialog = () => {
        setVisibleDialog(false);
        setDialogType(null);
    };

    const openBankDialog = () => {
        setBankDialogVisible(true);
    };

    const handleHideBankDialog = () => {
        setBankDialogVisible(false);
    };

    const fetchBankDetails = async () => {
        try {
          const facilityId = await getItem('selectedFacilityId');
          const response = await makeRequest2(getBankDetails + '/' + facilityId, 'GET', {});
    
          if (response.success) {
            setBankDetails(response.data);
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error')
        }
    };

    const HandleBankSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const bankDetails = {
                accountName,
                accountNumber,
                bankName,
                branchName,
                branchCode,
                bankCode,
            }

            if(!bankDetails.accountName || !bankDetails.accountNumber || !bankDetails.bankName || !bankDetails.branchName){
                toastify('All fields are required', 'error');
            }

            const response = await makeRequest2(addBankDetails + '/' + facilityId, 'POST', bankDetails);
            if (response.success) {
                toastify('Bank Details added successfully', 'success');
                setAccountName('');
                setAccountNumber('');
                setBankName('');
                setBranchName('');
                setBranchCode('');
                setBankCode('');
                handleHideBankDialog();
                fetchBankDetails();
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };


    const fetchInvoiceBillingData = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(getInvoiceBillingURL + '/' + facilityId, 'GET');
            
            if (response.success && response.data) {
                // Access the inner data object
                const { dueDays, levyNote } = response.data.data; // Update this line
    
                setDueDays(dueDays || '');
                setLevyNote(levyNote || '');
            } else {
                // If no data exists, keep the inputs empty (defaults).
                toastify('No existing data found', 'info');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const fetchLevyTypes = async () => {
        try {
          const facilityId = await getItem('selectedFacilityId');
          const response = await makeRequest2(getLevyTypes + '/' + facilityId, 'GET', {});
    
          if (response.success) {
            setLevyTypes(response.data);
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error')
        }
    };

    const fetchPenalties = async () => {
        try {
          const facilityId = await getItem('selectedFacilityId');
          const response = await makeRequest2(getPenalties + '/' + facilityId, 'GET', {});
    
          if (response.success) {
            setPenalties(response.data);
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error')
        }
    };

    const fetchReminders = async () => {
        try {
          const facilityId = await getItem('selectedFacilityId');
          const response = await makeRequest2(getReminders + '/' + facilityId, 'GET', {});
    
          if (response.success) {
            setReminders(response.data);
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error')
        }
    };

    const fetchTaxes = async () => {
        try {
          const facilityId = await getItem('selectedFacilityId');
          const response = await makeRequest2(getTaxes + '/' + facilityId, 'GET', {});
    
          if (response.success) {
            setTaxes(response.data);
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error')
        }
    };

    const handlePenaltySubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const percentageBoolean = percentage === 'Yes'; // true if 'Yes', false if 'No'

            const penaltyData = {
                title,
                module,
                type,
                effectDays,
                percentage: percentageBoolean,
                amount,
            }

            if(!penaltyData.title || !penaltyData.module || !penaltyData.type || !penaltyData.effectDays || !penaltyData.percentage  === undefined || !penaltyData.amount){
                toastify('All fields are required', 'error');
            }

            const response = await makeRequest2(addPenalty + '/' + facilityId, 'POST', penaltyData);
            if (response.success) {
                toastify('Penalty added successfully', 'success');
                setTitle('');
                setModule('');
                setType('');
                setEffectDays('');
                setPercentage('');
                setAmount('');
                handleHideDialog();
                fetchPenalties();
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const handleReminderSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const reminderData = {
                title,
                module,
                type,
                time,
                day
            }

            if(!reminderData.title || !reminderData.module || !reminderData.type || !reminderData.time || !reminderData.day){
                toastify('All fields are required', 'error');
            }

            const response = await makeRequest2(addReminder + '/' + facilityId, 'POST', reminderData);
            if (response.success) {
                toastify('Penalty added successfully', 'success');
                setTitle('');
                setModule('');
                setType('');
                setTime('');
                setDay('');
                handleHideDialog();
                fetchReminders();
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const handleTaxSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const percentageBoolean = percentage === 'Yes'; // true if 'Yes', false if 'No'

            const taxData = {
                type,
                percentage: percentageBoolean,
                amount,
            }

            if(!taxData.type || !taxData.percentage === undefined || !taxData.amount){
                toastify('All fields are required', 'error');
            }

            const response = await makeRequest2(addTax + '/' + facilityId, 'POST', taxData);
            if (response.success) {
                toastify('Tax added successfully', 'success');
                setType('');
                setPercentage('');
                setAmount('');
                handleHideDialog();
                fetchTaxes();
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const handleInvoiceBilling = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const billingData = {
                dueDays,
                levyNote
            }

            if(!billingData.dueDays || !billingData.levyNote){
                toastify('All fields are required', 'error');
            }

            const response = await makeRequest2(addInvoiceBillingURL + '/' + facilityId, 'POST', billingData);
            if (response.success) {
                toastify('Invoice billing settings Updated successfully', 'success');
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        setAmount(value);

        // Validate amount based on percentage selection
        if (percentage === 'Yes') {
            if (value < 1 || value > 100) {
                setAmountError('Amount must be greater than 1 and less than 100 when Percentage is Yes.');
            } else {
                setAmountError('');
            }
        } else {
            setAmountError(''); // Clear error if percentage is not 'Yes'
        }
    };

    const actionTemplate = (rowData, table, type, showEdit = true) => {
        const isDisabled = rowData.disabled; // Check if the row is disabled
    
        return (
          <div className="actions">
            {showEdit && (
            <Button
                icon="ti ti-edit"
                className="p-button-rounded p-button-info mr-2"
                onClick={() => handleEdit(rowData, table, type)}  // Pass type to handleEdit
                tooltip="Edit"
                disabled={isDisabled}  // Disable edit button if the row is disabled
            />
            )}
            <Button
            icon="ti ti-trash"
            className="p-button-rounded p-button-danger mr-2"
            onClick={() => {
                setSelectedId(rowData._id);  // Store ID based on the row
                setConfirmAction('delete');  // Set action to delete
                setEntityType(type);  // Set type of entity (penalty/violation)
                setConfirmDialogVisible(true);
            }}
            tooltip="Delete"
            disabled={!isDisabled}  // Allow deletion only if the row is disabled
            />
            <Button
            icon={`ti ${isDisabled ? 'ti-lock-open' : 'ti-lock'}`}  // Fixed template literal for dynamic icon
            className="p-button-rounded p-button-warning"
            onClick={() => {
                setSelectedId(rowData._id);  // Store ID based on the row
                setConfirmAction(isDisabled ? 'enable' : 'disable');  // Set action to enable/disable
                setSelectedDisableStatus(isDisabled);  // Store current status
                setEntityType(type);  // Set type of entity
                setConfirmDialogVisible(true);
            }}
            tooltip={isDisabled ? "Enable" : "Disable"}  // Fixed dynamic tooltip
            />
          </div>
    
    
    
        );
    };


    const HandlePenaltyEdit = async () => {
        try {
          const updatedData = {
            effectDays,
            amount,
          };
          console.log("Updated Data:", updatedData);
    
          const response = await makeRequest2(editPenalty + '/' + penaltyId, 'POST', updatedData);
    
          if (response.success) {
            toastify('Penalty Updated', 'success');
            setEffectDays('');
            setAmount('');
            setEditDialogVisible(false);
            fetchPenalties();
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          console.log(err);
          toastify(err.message, 'error');
        }
    };

    const HandleReminderEdit = async () => {
        try {
          const updatedData = {
            time,
            day,
          };
    
          const response = await makeRequest2(editReminder + '/' + reminderId, 'POST', updatedData);
          console.log("RESPONSE",reminderId);
    
          if (response.success) {
            toastify('Reminder Updated', 'success');
            setTime('');
            setDay('');
            setEditDialogVisible(false);
            fetchReminders();
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          console.log(err);
          toastify(err.message, 'error');
        }
    };


    const handleDeletePenalty = async (penaltyId) => {
        try {
          const response = await makeRequest2(deletePenalty + '/' + penaltyId, 'DELETE');
    
          if (response.success) {
            toastify(response.data, 'success');
            fetchPenalties();
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error');
        }
    
    };

    const handleDisablePenalty = async (penaltyId, currentStatus) => {
        const action = currentStatus ? 'enable' : 'disable';
    
        try {
          const newDisabledStatus = !currentStatus;
    
          const response = await makeRequest2(
            `${disablePenalty}/${penaltyId}`,
            'PATCH',
            { disabled: newDisabledStatus }
          );
          console.log("STATUS", newDisabledStatus)
    
          if (response.success) {
            toastify(response.data, 'success');
            fetchPenalties();  // Refresh the data
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error');
        }
    };

    const handleDeleteReminder = async (reminderId) => {
        try {
          const response = await makeRequest2(deleteReminder + '/' + reminderId, 'DELETE');
    
          if (response.success) {
            toastify(response.data, 'success');
            fetchReminders();
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error');
        }
    
    };

    const handleDisableReminder = async (reminderId, currentStatus) => {
        const action = currentStatus ? 'enable' : 'disable';
    
        try {
          const newDisabledStatus = !currentStatus;
    
          const response = await makeRequest2(
            `${disableReminder}/${reminderId}`,
            'PATCH',
            { disabled: newDisabledStatus }
          );
    
          if (response.success) {
            toastify(response.data, 'success');
            fetchReminders();  // Refresh the data
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error');
        }
    };

    const handleDeleteTax = async (taxId) => {
        try {
          const response = await makeRequest2(deleteTax + '/' + taxId, 'DELETE');
    
          if (response.success) {
            toastify(response.data, 'success');
            fetchTaxes();
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error');
        }
    
    };

    const handleDisableTax = async (taxId, currentStatus) => {
        const action = currentStatus ? 'enable' : 'disable';
    
        try {
          const newDisabledStatus = !currentStatus;
    
          const response = await makeRequest2(
            `${disableTax}/${taxId}`,
            'PATCH',
            { disabled: newDisabledStatus }
          );
    
          if (response.success) {
            toastify(response.data, 'success');
            fetchTaxes();  // Refresh the data
          } else {
            throw new Error(response.error);
          }
        } catch (err) {
          toastify(err.message, 'error');
        }
    };

    const HandleEditSettings = async (selectedRowData) => {
        if (selectedTable === 'penalties') {
            await HandlePenaltyEdit(selectedRowData);  // Pass the row data to the edit function
        } else if (selectedTable === 'reminders') {
            await HandleReminderEdit(selectedRowData);  // Handle reminders update
        }
        setEditDialogVisible(false);  // Close the dialog after the update
    };

    const handleConfirmAction = async () => {
        setConfirmDialogVisible(false);
    
        if (confirmAction === 'delete') {
            if (entityType === 'penalty') {
                await handleDeletePenalty(selectedId);  
            } else if (entityType === 'reminder') {
                await handleDeleteReminder(selectedId);  
            }
            else if (entityType === 'tax') {
                await handleDeleteTax(selectedId); 
            }
        } else if (confirmAction === 'enable' || confirmAction === 'disable') {
            if (entityType === 'penalty') {
                await handleDisablePenalty(selectedId, selectedDisableStatus);  // Enable/disable penalty
            } else if (entityType === 'reminder') {
                await handleDisableReminder(selectedId, selectedDisableStatus);  // Enable/disable violation
            }
            else if (entityType === 'tax') {
                await handleDisableTax(selectedId, selectedDisableStatus);  // Enable/disable violation
            }
        }

        if (confirmAction === 'set as default') {
            if (entityType === 'Bank Record') {
                await setDefaultAccount(selectedId);  
            }
        } else if (confirmAction === 'unset as default') {
            if (entityType === 'Bank Record') {
                await unsetDefaultAccount(selectedId);
            } 
        }
       
        
    };

    const statusTemplate = (rowData) => {
        const isDisabled = rowData.disabled;
    
        return (
          <span
            style={{
              fontWeight: 'bold',
              color: isDisabled ? '#ff4d4d' : '#4caf50',  // Red for Disabled, Green for Enabled
              padding: '5px 10px',
              borderRadius: '15px',
              backgroundColor: isDisabled ? '#ffebeb' : '#e8f5e9'  // Light red or green background
            }}
          >
            {isDisabled ? 'Disabled' : 'Enabled'}
          </span>
        );
    };

    // const percentageBodyTemplate = (rowData) => {
    //     return rowData.percentage ? 'Yes' : 'No'; // Convert Boolean to Yes/No
    // };

    const handlePenaltySearch = async (value) => {
        try {
          setPenaltySearchTerm(value);
          if (value === '') {
            fetchPenalties()
          } else {
            const results = await filterObjectsByAllProperties(penalties, value);
            setPenalties(results);
          }
        } catch (error) {
          toastify(error.message, 'error');
        }
    };

    const handleReminderSearch = async (value) => {
        try {
          setReminderSearchTerm(value);
          if (value === '') {
            fetchReminders()
          } else {
            const results = await filterObjectsByAllProperties(reminders, value);
            setReminders(results);
          }
        } catch (error) {
          toastify(error.message, 'error');
        }
    };

    const handleTaxSearch = async (value) => {
        try {
          setTaxSearchTerm(value);
          if (value === '') {
            fetchTaxes()
          } else {
            const results = await filterObjectsByAllProperties(taxes, value);
            setTaxes(results);
          }
        } catch (error) {
          toastify(error.message, 'error');
        }
    };

    const amountBodyTemplate = (rowData) => {
        return `${rowData.amount}%`;
    };

    const setDefaultAccount = async (accountId) => {
        try {
            if (!accountId) {
                throw new Error('Account ID is missing');
            }
            
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(addDefaultAccount + '/' + accountId, 'POST', { facilityId });
            console.log('Response:', accountId);
    
            if (response.success) {
                await fetchBankDetails();
    
                toastify('Default account updated successfully', 'success');
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    const unsetDefaultAccount = async (accountId) => {
        try {
            if (!accountId) {
                throw new Error('Account ID is missing');
            }
    
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(removeDefaultAccount + '/' + accountId, 'POST', { facilityId });
            console.log('Response:', accountId);
    
            if (response.success) {
                await fetchBankDetails();
                toastify('Default account unset successfully', 'success');
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };
    


    useEffect(() => {
        if (module === 'levy') {
            fetchLevyTypes();
        } else {
            setLevyTypes([]);
        }
        fetchPenalties();
        fetchTaxes();
        fetchReminders();
        fetchInvoiceBillingData();
        fetchBankDetails();
    }, [module]);

    const renderDialogContent = () => {
        if (dialogType === 'penalty') {
            return (
                <div>
                    <div className="form-group mb-4">
                        <label htmlFor="title">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="type">Module</label>
                        <select
                            className="form-control mt-2"
                            value={module}
                            onChange={(e) => setModule(e.target.value)}
                        >
                            <option value="">-- Select --</option>
                            <option value="levy">Levy</option>
                            <option value="utility">Utility</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="penaltyFor">Penalty For</label>
                        {module === 'levy' ? (
                        <select
                            className="form-control mt-2"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="">-- Select Levy Type --</option>
                            {levyTypes.map((levy) => (
                                <option key={levy.id} value={levy.id}>{levy.levyType}</option>
                            ))}
                        </select>
                        ) : (
                        <input
                            type="text"
                            className="form-control"
                            id="penaltyFor"
                            name="penaltyFor"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                        )}
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="effect">Effect Days</label>
                        <input
                            type="number"
                            className="form-control"
                            id="effect"
                            name="effect"
                            value={effectDays}
                            onChange={(e) => setEffectDays(e.target.value)}
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="type">Percentage</label>
                        <select
                            className="form-control mt-2"
                            value={percentage}
                            onChange={(e) => {
                                setPercentage(e.target.value);
                                setAmount(0); // Reset amount when percentage changes
                                setAmountError(''); // Clear any error
                            }}
                        >
                            <option value="">-- Select --</option>
                            <option value="Yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="amount">Amount</label>
                        <input
                            type="number"
                            className="form-control"
                            id="amount"
                            name="amount"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                        {amountError && <small className="text-danger">{amountError}</small>} {/* Display error message */}
                    </div>
                </div>
            );
        } else if (dialogType === 'reminder') {
            return (
                <div>
                    <div className="form-group mb-4">
                        <label htmlFor="title">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="type">Module</label>
                        <select
                            className="form-control mt-2"
                            value={module}
                            onChange={(e) => setModule(e.target.value)}
                        >
                            <option value="">-- Select --</option>
                            <option value="levy">Levy</option>
                            <option value="utility">Utility</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="reminderFor">Reminder For</label>
                        {module === 'levy' ? (
                        <select
                            className="form-control mt-2"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="">-- Select Levy Type --</option>
                            {levyTypes.map((levy) => (
                                <option key={levy.id} value={levy.id}>{levy.levyType}</option>
                            ))}
                        </select>
                        ) : (
                        <input
                            type="text"
                            className="form-control"
                            id="reminderFor"
                            name="reminderFor"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                        )}
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="time">Time</label>
                        <input
                            type="time"
                            className="form-control"
                            id="time"
                            name="time"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="day">Day</label>
                        <input
                            type="number"
                            className="form-control"
                            id="day"
                            name="day"
                            value={day}
                            onChange={(e) => setDay(e.target.value)}
                        />
                    </div>
                </div>
            );
        } else if (dialogType === 'tax') {
            return (
                <div>
                    <div className="form-group mb-4">
                        <label htmlFor="type">Type</label>
                        <input
                            type="text"
                            className="form-control"
                            id="type"
                            name="type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="type">Percentage</label>
                        <select
                            className="form-control mt-2"
                            value={percentage}
                            onChange={(e) => {
                                setPercentage(e.target.value);
                                setAmount(0); // Reset amount when percentage changes
                                setAmountError(''); // Clear any error
                            }}
                        >
                            <option value="">-- Select --</option>
                            <option value="Yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="amount">Amount</label>
                        <input
                            type="number"
                            className="form-control"
                            id="amount"
                            name="amount"
                            value={amount}
                            onChange={handleAmountChange}
                        />
                        {amountError && <small className="text-danger">{amountError}</small>} {/* Display error message */}
                    </div>
                </div>
            );
        }
        return null;
    };


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/settings"}>Settings</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body py-0">
                                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                                <i className="fas fa-align-left me-2"></i>Facility Information
                                                            </a>
                                                        </li>
                                                        
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                                <i className="fas fa-flag-checkered me-2"></i>Penalties
                                                            </a>
                                                        </li>

                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                                <i className="fas fa-backward me-2"></i>Reminders
                                                            </a>
                                                        </li>
                                                       
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="true">
                                                                <i className="fas fa-money-check me-2"></i>Tax
                                                            </a>
                                                        </li>

                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-5" role="tab" aria-selected="true">
                                                                <i className="fas fa-file-invoice me-2"></i>Invoice Billing Config
                                                            </a>
                                                        </li>

                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-6" data-bs-toggle="tab" href="#profile-6" role="tab" aria-selected="true">
                                                                <i className="fas fa-file-invoice-dollar me-2"></i>Bank Details
                                                            </a>
                                                        </li>
                                                       
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="tab-content">

                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="card">
                                                        <div class="container m-5">
                                                            <div class="row">
                                                                <div class="col-md-5">
                                                                    <h2>Upload Property Logo</h2>
                                                                    <p>
                                                                        Please upload the logo image. Ensure the image is clear and represents your property well. Accepted formats include JPEG, PNG, and GIF.
                                                                    </p>
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <form>
                                                                        <div class="mb-3">
                                                                            <label for="file-input" class="form-label">Property Logo Image:</label>
                                                                            <div class="p-4 border border-2 border-dashed rounded bg-light text-center position-relative">
                                                                                <input type="file" class="form-control position-absolute top-0 start-0 w-100 h-100 opacity-0" id="file-input"/>
                                                                                <p id="file-name" class="mb-0">No file selected.</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="d-flex justify-content-end">
                                                                            <button type="button" class="btn btn-dark">Update</button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>

                                                <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    
                                                    <div className="card">
                                                        <div className='card-body'>
                                                            <div className="row">
                                                                <div className="col-lg-12 col-xxl-12">
                                                               
                                                                    <div className="card-body position-relative">
                                                                        <div className="row">

                                                                            <div className="col-md-3 col-xs-12 mb-3">
                                                                            <input
                                                                                className="form-control"
                                                                                placeholder="Search here"
                                                                                value={penaltySearchTerm}
                                                                                onChange={(e) => { handlePenaltySearch(e.target.value) }}
                                                                            />
                                                                            </div>

                                                                            <div className="col-md-9 col-xs-12 mb-3">
                                                                            <div style={{ float: 'right' }}>
                                                                                <button className='btn btn-primary' onClick={() => handleShowDialog('penalty')}>Add New Penalty</button>
                                                                                &nbsp;
                                                                            </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                            <div className="dt-responsive table-responsive">
                                                                                <DataTable
                                                                                value={penalties}
                                                                                emptyMessage="No penalties found."
                                                                                sortMode="multiple"
                                                                                paginator
                                                                                rows={5}
                                                                                stripedRows
                                                                                tableStyle={{ minWidth: '50rem' }}
                                                                                >
                                                                                <Column field="title" header="Title" />
                                                                                <Column field="module" header="Module" />
                                                                                <Column field="type" header="Type" />
                                                                                <Column field="effectDays" header="Effect Days" />
                                                                                {/* <Column field='percentage' header="Percentage" body={percentageBodyTemplate}/> */}
                                                                                <Column field="amount" header="Amount" body={amountBodyTemplate}/>
                                                                                <Column body={statusTemplate} header="Status" />
                                                                                <Column body={(rowData) => actionTemplate(rowData, 'penalties', 'penalty')}  header="Action" />
                                                                                </DataTable>

                                                                                <ConfirmDialog
                                                                                visible={isConfirmDialogVisible}
                                                                                onHide={() => setConfirmDialogVisible(false)}
                                                                                message={`Are you sure you want to ${confirmAction} this ${entityType}?`}
                                                                                onConfirm={handleConfirmAction}
                                                                                />
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                               
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        
                                                </div>

                                                <div className="tab-pane" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-3">
                                                    
                                                        <div className="card">
                                                            <div className='card-body'>
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-xxl-12">
                                                                    
                                                                        <div className="card-body position-relative">
                                                                            <div className="row">

                                                                                <div className="col-md-3 col-xs-12 mb-3">
                                                                                <input
                                                                                    className="form-control"
                                                                                    placeholder="Search here"
                                                                                    value={reminderSearchTerm}
                                                                                    onChange={(e) => { handleReminderSearch(e.target.value) }}
                                                                                />
                                                                                </div>

                                                                                <div className="col-md-9 col-xs-12 mb-3">
                                                                                <div style={{ float: 'right' }}>
                                                                                    <button className='btn btn-primary' onClick={() => handleShowDialog('reminder')}>Add New Reminder</button>
                                                                                    &nbsp;
                                                                                </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                <div className="dt-responsive table-responsive">
                                                                                    <DataTable
                                                                                    value={reminders}
                                                                                    emptyMessage="No Reminders found."
                                                                                    sortMode="multiple"
                                                                                    paginator
                                                                                    rows={5}
                                                                                    stripedRows
                                                                                    tableStyle={{ minWidth: '50rem' }}
                                                                                    >
                                                                                    <Column field="title" header="Title" />
                                                                                    <Column field="module" header="Module" />
                                                                                    <Column field='type' header="What is the reminder for" />
                                                                                    <Column field="time" header="Time" />
                                                                                    <Column field="day" header="Day" />
                                                                                    <Column body={statusTemplate} header="Status" />
                                                                                    <Column body={(rowData) => actionTemplate(rowData, 'reminders', 'reminder')}  header="Action" />
                                                                                    </DataTable>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                </div>

                                                <div className="tab-pane" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                                  
                                                        <div className="card">
                                                            <div className='card-body'>
                                                                <div className="row">
                                                                    <div className="col-lg-12 col-xxl-12">
                                                                    
                                                                        <div className="card-body position-relative">
                                                                            <div className="row">

                                                                                <div className="col-md-3 col-xs-12 mb-3">
                                                                                <input
                                                                                    className="form-control"
                                                                                    placeholder="Search here"
                                                                                    value={taxSearchTerm}
                                                                                    onChange={(e) => { handleTaxSearch(e.target.value) }}
                                                                                />
                                                                                </div>

                                                                                <div className="col-md-9 col-xs-12 mb-3">
                                                                                <div style={{ float: 'right' }}>
                                                                                    <button className='btn btn-primary' onClick={() => handleShowDialog('tax')}>Add New Tax</button>
                                                                                    &nbsp;
                                                                                </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12">
                                                                                <div className="dt-responsive table-responsive">
                                                                                    <DataTable
                                                                                    value={taxes}
                                                                                    emptyMessage="No Taxes found."
                                                                                    sortMode="multiple"
                                                                                    paginator
                                                                                    rows={5}
                                                                                    stripedRows
                                                                                    tableStyle={{ minWidth: '50rem' }}
                                                                                    >
                                                                                    <Column field="type" header="Type" />
                                                                                    <Column field="amount" header="Amount" body={amountBodyTemplate} />
                                                                                    <Column body={statusTemplate} header="Status" />
                                                                                    <Column body={(rowData) => actionTemplate(rowData, 'taxes', 'tax', false)}  header="Actions" />
                                                                                    </DataTable>
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                   
                                                </div>

                                                <div className="tab-pane" id="profile-5" role="tabpanel" aria-labelledby="profile-tab-5">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card">
                                                                
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        
                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label>Days To due Date</label>
                                                                                <input type="number" class="form-control" id="exampleFormControlInput1"
                                                                                    value={dueDays}
                                                                                    onChange={(e) => setDueDays(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12">
                                                                            <div className="mb-3">
                                                                                <label className="form-label">Levy Note</label>
                                                                                    <>
                                                                                    <Editor
                                                                                    apiKey='fkke97s8l0usngj1muqz6ewre8zzm7flcdz2532wg0l2a9ag'
                                                                                    onInit={(_evt, editor) => editorRef.current = editor}
                                                                                    value={levyNote}
                                                                                    init={{
                                                                                    height: 500,
                                                                                    menubar: false,
                                                                                    plugins: [
                                                                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                                                    ],
                                                                                    toolbar: 'undo redo | blocks | ' +
                                                                                        'bold italic forecolor | alignleft aligncenter ' +
                                                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                                        'removeformat | help',
                                                                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                                    }}
                                                                                    onEditorChange={(content) => setLevyNote(content)} // Update state on change
                                                                                />
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 text-end">
                                                                        <button className="btn btn-primary" onClick={handleInvoiceBilling}>Update</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>


                                                <div className="tab-pane" id="profile-6" role="tabpanel" aria-labelledby="profile-tab-6">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card">
                                                                
                                                                <div className="card-body">
                                                                    
                                                                        
                                                                    <div className="row">

                                                                        <div className="col-md-3 col-xs-12 mb-3">
                                                                            {/* <input
                                                                                className="form-control"
                                                                                placeholder="Search here"
                                                                                // value={searchTerm}
                                                                                // onChange={(e) => { handleInputSearch(e.target.value) }}
                                                                            /> */}
                                                                        </div>

                                                                        <div className="col-md-9 col-xs-12 mb-3">
                                                                            <div style={{ float: 'right' }}>
                                                                                <button className='btn btn-primary' onClick={openBankDialog}>Add New Bank Account</button>
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                        <div className="dt-responsive table-responsive">
                                                                            <DataTable
                                                                            value={bankDetails}
                                                                            emptyMessage="No Bank records found."
                                                                            sortMode="multiple"
                                                                            paginator
                                                                            rows={5}
                                                                            stripedRows
                                                                            tableStyle={{ minWidth: '50rem' }}
                                                                            >
                                                                            <Column field="accountName" header="A/C Name" />
                                                                            <Column field="accountNumber" header="A/C Number" />
                                                                            <Column field="bankName" header="Bank Name" />
                                                                            <Column field="branchName" header="Branch Name" />
                                                                            <Column field="branchCode" header="Branch Code" />
                                                                            <Column field="bankCode" header="Bank Code" />
                                                                            <Column
                                                                            field="isDefault"
                                                                            header="Status"
                                                                            body={(rowData) => (
                                                                                rowData.isDefault ? (
                                                                                <span style={{ color: 'green', fontWeight: 'bold', padding: '5px 10px',  borderRadius: '15px', backgroundColor: '#e8f5e9' }}>Default</span>
                                                                                ) : (
                                                                                ""
                                                                                )
                                                                            )}
                                                                            />
                                                                            <Column
                                                                                header="Actions"
                                                                                body={(rowData) => (
                                                                                    rowData.isDefault ? (
                                                                                        <button
                                                                                            className="btn btn-outline-danger"
                                                                                            onClick={() => {
                                                                                                setSelectedId(rowData._id);  // Store ID based on the row
                                                                                                setConfirmAction('unset as default');  // Set action to delete
                                                                                                setEntityType('Bank Record');  // Set type of entity (penalty/violation)
                                                                                                setConfirmDialogVisible(true);
                                                                                                }}
                                                                                            title="Unset Default"
                                                                                        >
                                                                                            <i className="fas fa-times-circle" style={{ fontSize: '1.2rem' }}></i> {/* More appealing X Icon */}
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            className="btn btn-outline-success"
                                                                                            title="Set Default"
                                                                                            onClick={() => {
                                                                                                setSelectedId(rowData._id);  // Store ID based on the row
                                                                                                setConfirmAction('set as default');  // Set action to delete
                                                                                                setEntityType('Bank Record');  // Set type of entity (penalty/violation)
                                                                                                setConfirmDialogVisible(true);
                                                                                                }}
                                                                                        >
                                                                                            <i className="fas fa-check-circle" style={{ fontSize: '1.2rem' }}></i> {/* More appealing Tick Icon */}
                                                                                        </button>
                                                                                    )
                                                                                )}
                                                                            />
                                                                            </DataTable>
                                                                        </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                              
                                            </div>

                                             {/* Reusable Dialog for Penalty, Reminder, and Tax */}
                                            <ReusableDialog
                                                header={
                                                    dialogType === 'penalty' ? "Add New Penalty"
                                                    : dialogType === 'reminder' ? "Add New Reminder"
                                                    : "Add New Tax"
                                                }
                                                visible={visibleDialog}
                                                onHide={handleHideDialog}
                                                submitAction={
                                                    dialogType === 'penalty' ? handlePenaltySubmit
                                                    : dialogType === 'reminder' ? handleReminderSubmit
                                                    : handleTaxSubmit
                                                }
                                            >
                                                {renderDialogContent()}
                                            </ReusableDialog>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <Dialog header={`Edit ${selectedTable.charAt(0).toUpperCase() + selectedTable.slice(1)}`} visible={editDialogVisible} style={{ width: '30vw' }} onHide={() => setEditDialogVisible(false)}>
                <div className="row">
                    <div className="col-md-12 mt-3">
                    {selectedTable === 'penalties' && (
                        <>
                        <div className='mb-4'>
                            <label>Effect Days:</label>
                            <input className="form-control mt-2" type="text" placeholder="Effect Days" 
                           value={effectDays} // Bind directly to effectDays state
                           onChange={(e) => setEffectDays(e.target.value)}/>
                        </div>
                        <div className='mb-4'>
                            <label>Amount:</label>
                            <input className="form-control mt-2" type="text" placeholder="Amount" 
                            value={amount} // Bind directly to amount state
                            onChange={(e) => setAmount(e.target.value)}/>
                        </div>
                        </>
                    )}
                    {selectedTable === 'reminders' && (
                        <>
                        <div className='mb-4'>
                            <label>Time:</label>
                            <input className="form-control mt-2" type="time" placeholder="Effect Days" 
                            value={time} // Bind directly to effectDays state
                            onChange={(e) => setTime(e.target.value)}/>
                        </div>
                        <div className='mb-4'>
                            <label>Days:</label>
                            <input className="form-control mt-2" type="text" placeholder="Amount" 
                            value={day} // Bind directly to amount state
                            onChange={(e) => setDay(e.target.value)}/>
                        </div>
                        </>
                    )}
                    <div className='mt-4' style={{ float: 'right' }}>
                        <button className="btn btn-primary" onClick={() => HandleEditSettings(selectedRowData)}>Submit</button>
                    </div>
                    </div>
                </div>
            </Dialog>

            <Dialog header={`Add Bank Details`} visible={bankDialogVisible} style={{ width: '50vw' }} onHide={() => { if (!bankDialogVisible) return; setBankDialogVisible(false); }}>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <div className='mb-4'>
                        <label>Account Name:</label>
                        <input className="form-control mt-2" type="text" value={accountName} onChange={(e) => {
                            const value = e.target.value;
                            setAccountName(value)

                        }}></input>
                        </div>

                        <div className='mb-4'>
                        <label>Account Number:</label>
                        <input className="form-control mt-2" type="text" value={accountNumber} onChange={(e) => {
                            const value = e.target.value;
                            setAccountNumber(value)

                        }}></input>
                        </div>

                        <div className='mb-4'>
                        <label>Bank Name:</label>
                        <input className="form-control mt-2" type="text" value={bankName} onChange={(e) => {
                            const value = e.target.value;
                            setBankName(value)

                        }}></input>
                        </div>

                        <div className='mb-4'>
                        <label>Branch Name:</label>
                        <input className="form-control mt-2" type="text" value={branchName} onChange={(e) => {
                            const value = e.target.value;
                            setBranchName(value)

                        }}></input>
                        </div>

                        <div className='mb-4'>
                        <label>Branch Code:</label>
                        <input className="form-control mt-2" type="text" value={branchCode} onChange={(e) => {
                            const value = e.target.value;
                            setBranchCode(value)

                        }}></input>
                        </div>

                        <div className='mb-4'>
                        <label>Bank Code:</label>
                        <input className="form-control mt-2" type="text" value={bankCode} onChange={(e) => {
                            const value = e.target.value;
                            setBankCode(value)

                        }}></input>
                        </div>

                        <div className='mt-4' style={{ float: 'right' }}>
                        <button className="btn btn-primary" onClick={HandleBankSubmit}>Submit</button>
                        </div>

                    </div>

                </div>

            </Dialog>


        </Layout>
    )
}


export default Settings