import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import Layout from '../../component/layout'
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom'
import { OverlayPanel } from 'primereact/overlaypanel';
import { Dialog } from 'primereact/dialog';
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';


function VisitorRequests () {
    const location = useLocation();
    const [formData, setFormData] = useState(location.state?.formData || null);
    const overlayPanelRef = useRef(null);

    const [isDialogVisible, setIsDialogVisible] = useState(false); // State to control dialog visibility
    const [selectedVisitor, setSelectedVisitor] = useState(null);

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-ellipsis-v"
                    className="p-button-rounded p-button-text"
                    onClick={(e) => overlayPanelRef.current.toggle(e)} // Show the popup on click
                />
                <OverlayPanel ref={overlayPanelRef} dismissable>
                    <div className="p-px-3">
                        <Button label="Confirm Visit with OTP" icon="pi pi-check" className="p-button-text" onClick={() => handleConfirmVisit(rowData)} />
                    </div>
                </OverlayPanel>
            </React.Fragment>
        );
    };

    const handleConfirmVisit = (rowData) => {
        setSelectedVisitor(rowData); // Store the visitor data
        setIsDialogVisible(true);
    };

    useEffect(() => {
        if (!formData) {
            const storedFormData = localStorage.getItem('formData');
            if (storedFormData) {
                setFormData(JSON.parse(storedFormData));
            }
        }
    }, [formData]);

    return(
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/visitor_access_management/visitor_registration/visitor_requests"}>Visitor Requests</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                           
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">

                                                        <div className="col-lg-12 col-xxl-12">
                                                            <div className="card">
                                                                <div className="card-body position-relative">

                                                                    {/* <div className="col-md-3 col-xs-12  mb-3">
                                                                        <br />
                                                                        <input className="form-control" placeholder="Search here" value={''} onChange={(e) => { }} />

                                                                    </div> */}

                                                                    <div className="dt-responsive table-responsive">
                                                                        <DataTable value={formData ? [formData] : []} header="Visitor Requests" emptyMessage="No visitors requests found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>


                                                                            {/* <Column header="Visitor Name" body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}></Column> */}
                                                                            {/* <Column field="resident" header="Resident"></Column> */}
                                                                            <Column field="idNumber" header="ID No"></Column>
                                                                            <Column field="phoneNumber" header="Phone Number"></Column>
                                                                            <Column field="carRegistration" header="Car Registration"></Column>
                                                                            <Column field="" header="Visit Status"></Column>
                                                                            <Column body={actionBodyTemplate} header="Action"></Column>

                                                                        </DataTable>


                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <Dialog
                header="Confirm Visit"
                visible={isDialogVisible}
                style={{ width: '30vw' }}
                onHide={() => setIsDialogVisible(false)} // Close the dialog
            >
               <div className="row">
                    <div className="col-md-12 mt-3">
                        
                        
                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="ID number"
                                // value={idNumber} 
                                // onChange={(e) => {
                                //     let value = e.target.value;
                                //     setIdNumber(value)
                                // }}
                            ></input>
                        </div>

                         
                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="Car registration"
                                // value={phoneNumber} 
                                // onChange={(e) => {
                                //     let value = e.target.value;
                                //     setPhoneNumber(value)
                                // }}
                            ></input>
                        </div>

                         
                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="No of Occupants"
                                // value={carRegistration} 
                                // onChange={(e) => {
                                //     let value = e.target.value;
                                //     setCarRegistration(value)
                                // }}
                            ></input>
                        </div>

                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="OTP code"
                                // value={carRegistration} 
                                // onChange={(e) => {
                                //     let value = e.target.value;
                                //     setCarRegistration(value)
                                // }}
                            ></input>
                        </div>
                       
                        
                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary" 
                            // onClick={handleSubmitClick}
                            >
                                Submit
                            </button>
                        </div>

                    </div>

                </div>
            </Dialog>

        </Layout>
    )
}

export default VisitorRequests