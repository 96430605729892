import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../component/layout';
import QRCode from "react-qr-code";
import { toPng } from "html-to-image";
import download from "downloadjs";
import { makeRequest2 } from '../../../utils/makeRequest';
import {
    DisableCustomer,
    EnableCustomer,
    getCustomerURL,
    updateCustomer,
    getCustomerUnits,
    addUnoccupiedUnitToCustomer,
    addFamilyMemberToCustomer,
    getFamilyMembersForCustomer,
    addVehicleToCustomer,
    getVehiclesForCustomer,
    addStaffToCustomer,
    getStaffForCustomer
} from '../../../utils/urls';
import { toastify } from '../../../utils/toast';
import { unitTypes } from '../../../utils/data';
import { getItem } from '../../../utils/localStorage'
import { getUnitsForFacility } from '../../../utils/urls';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import ReusableDialog from '../component/reusableDialog';

const ViewCustomer = () => {
    const qrCodeRef = useRef();

    const navigate = useNavigate();
    const { customerId } = useParams();
    const [customer, setCustomer] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [units, setUnits] = useState([])
    const [customerUnits, setCustomerUnits] = useState([])
    const [addUnitDialog, setAddUnitDialogVisible] = useState(false)
    const [visitorQRCode, setVisitorQRCode] = useState(false)
    const [selectedUnits, setSelectedUnits] = useState([]);

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [relation, setRelation] = useState('')
    const [model, setModel] = useState('');
    const [plateNumber, setPlateNumber] = useState('');
    const [job, setJob] = useState('');
    const [selectedQRCode, setSelectedQRCode] = useState({})
    const [visibleQRCode, setVisibleQRCode] = useState(false)

    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogType, setDialogType] = useState(null);

    const [familyMembers, setFamilyMembers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [staff, setStaff] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');


    const handleDownloadQRCode = () => {
        if (qrCodeRef.current) {
            console.log(qrCodeRef.current)
            toPng(qrCodeRef.current)
                .then((dataUrl) => {

                    download(dataUrl, "qrcode.png");
                })
                .catch((err) => {
                    console.error("Oops, something went wrong!", err);
                });
        }
    };

    const openDialog = (type, customer) => {
        setDialogType(type);  // Set dialog type ('addFamily', 'addVehicle', 'addStaff')
        setDialogVisible(true);  // Open the dialog
    };

    const handleSubmit = () => {
        if (dialogType === 'addFamily') {
            submitFamily();
        } else if (dialogType === 'addVehicle') {
            submitVehicle();
        } else if (dialogType === 'addStaff') {
            submitStaff();
        }
    };
    const phoneNumberTemplate = (rowData) => {
        const phoneNumber = rowData.phoneNumber;
        return '+254' + phoneNumber
    }
    const qrCodeTemplate = (rowData) => {
        rowData.type = 'Family'
        rowData.customerId = customerId
        if (rowData.qrCode === undefined) {
            return `....`
        }
        else {
            if (rowData.qrCode) {
                return <button className="btn btn-dark btn-sm" onClick={
                    () => {
                        setSelectedQRCode(rowData)
                        setVisibleQRCode(true)
                    }
                }>QR Code</button>
            }
            else {
                return `....`
            }
        }
    }
    const qrCodeVehicleTemplate = (rowData) => {
        rowData.type = 'Vehicle'
        rowData.customerId = customerId
        if (rowData.qrCode === undefined) {
            return `....`
        }
        else {
            if (rowData.qrCode) {
                return <button className="btn btn-dark btn-sm" onClick={
                    () => {
                        setSelectedQRCode(rowData)
                        setVisibleQRCode(true)
                    }
                }>QR Code</button>
            }
            else {
                return `....`
            }
        }
    }
    const qrCodeStaffTemplate = (rowData) => {
        rowData.type = 'Staff'
        rowData.customerId = customerId
        console.log(rowData)
        if (rowData.qrCode === undefined) {
            return `....`
        }
        else {
            if (rowData.qrCode) {
                return <button className="btn btn-dark btn-sm" onClick={
                    () => {
                        setSelectedQRCode(rowData)
                        setVisibleQRCode(true)
                    }
                }>QR Code</button>
            }
            else {
                return `....`
            }
        }
    }


    const submitFamily = async () => {
        const familyData = {
            customerId, // Add customerId to the payload
            name,
            phone,
            relation,
            visitorQRCode
        };

        try {
            const response = await makeRequest2(`${addFamilyMemberToCustomer}/${customerId}`, 'POST', familyData);
            console.log('Customer Id', customerId)
            if (response.success) {
                toastify('Family member added successfully', 'success');
                resetForm(); // Reset form fields
                setDialogVisible(false); // Close the dialog
                fetchFamilyMembers();
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            console.log(err);
            toastify(err.message, 'error');
        }
    };

    const submitVehicle = async () => {
        const vehicleData = {
            customerId, // Add customerId to the payload
            name,
            model,
            plateNumber,
            visitorQRCode
        };

        try {
            const response = await makeRequest2(`${addVehicleToCustomer}/${customerId}`, 'POST', vehicleData);
            if (response.success) {
                toastify('Vehicle added successfully', 'success');
                resetForm(); // Reset form fields
                setDialogVisible(false); // Close the dialog
                fetchVehicles();
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            console.log(err);
            toastify(err.message, 'error');
        }
    };

    const submitStaff = async () => {
        const staffData = {
            customerId, // Add customerId to the payload
            name,
            job,
            phone,
            visitorQRCode
        };

        try {
            const response = await makeRequest2(`${addStaffToCustomer}/${customerId}`, 'POST', staffData);
            if (response.success) {
                toastify('Staff member added successfully', 'success');
                resetForm(); // Reset form fields
                setDialogVisible(false); // Close the dialog
                fetchStaff();
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            console.log(err);
            toastify(err.message, 'error');
        }
    };

    const resetForm = () => {
        setName('');
        setPhone('');
        setRelation('');
        setModel('');
        setPlateNumber('');
        setJob('');
    };

    const fetchFamilyMembers = async () => {
        try {
            const response = await makeRequest2(`${getFamilyMembersForCustomer}/${customerId}`, 'GET', {});
            if (response.success) {
                setFamilyMembers(response.data.familyMembers);
            } else {
                throw new Error('Error fetching family members')
            }
        } catch (err) {
            console.error('Error fetching family members:', err);
        }
    };

    const fetchVehicles = async () => {
        try {
            const response = await makeRequest2(`${getVehiclesForCustomer}/${customerId}`, 'GET', {});
            if (response.success) {
                setVehicles(response.data.vehicles);
            } else {
                throw new Error('Error fetching Vehicles')
            }
        } catch (err) {
            console.error('Error fetching vehicles:', err);
        }
    };

    const fetchStaff = async () => {
        try {
            const response = await makeRequest2(`${getStaffForCustomer}/${customerId}`, 'GET', {});
            if (response.success) {
                setStaff(response.data.staff);
            } else {
                throw new Error('Error fetching staff')
            }
        } catch (err) {
            console.error('Error fetching staff:', err);
        }
    };

    const fetchUnits = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${facilityId}`, 'GET', {});
            if (response.success) {
                const non_occupied_units = response.data.filter((x) => {
                    return x.homeOwnerId === undefined && x.tenantId === undefined
                })

                setUnits(non_occupied_units);
            } else {
                throw new Error('Error fetching units')
            }
        } catch (err) {
            console.error('Error fetching units:', err);
        }
    };

    const addUnoccupiedUnit = async () => {
        try {
            const data = {
                units: selectedUnits,
            };

            const response = await makeRequest2(`${addUnoccupiedUnitToCustomer}/${customerId}`, 'POST', data);


            if (response.success) {
                toastify('Unit added successfully', 'success');
                setSelectedUnits([]);
                setAddUnitDialogVisible(false)
                getUnitsForCustomer();
                fetchUnits()
            }
            else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    }


    const getCustomer = async () => {
        try {
            const response = await makeRequest2(getCustomerURL + '/' + customerId, 'GET')

            if (response.success === true) {
                const customer = response.data;
                setCustomer(customer)
                setFirstName(customer.firstName);
                setLastName(customer.lastName);
                setPhoneNumber(customer.phoneNumber);
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const getUnitsForCustomer = async () => {
        try {
            console.log(`Fetching units for customerId: ${customerId}`);
            const response = await makeRequest2(`${getCustomerUnits}/${customerId}`, 'GET', {});
            setCustomerUnits(response.data);
        }
        catch (error) {
            toastify('Error fetching units: ' + error.message);  // Optional: show an error toast
        }
    };

    const HandleCustomerUpdate = async () => {
        try {
            const updatedCustomer = {
                firstName,
                lastName,
                phoneNumber,
            };

            const response = await makeRequest2(`${updateCustomer}/${customerId}`, 'POST', updatedCustomer);

            if (response.success) {
                toastify(response.data, 'success');
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                getCustomer();
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            console.log(err);
            toastify(err.message, 'error');
        }
    };


    const handleFamilyInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                fetchFamilyMembers()
            } else {
                const results = await filterObjectsByAllProperties(familyMembers, value);
                setFamilyMembers(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };

    const handleVehicleInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                fetchVehicles()
            } else {
                const results = await filterObjectsByAllProperties(vehicles, value);
                setVehicles(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };

    const handleStaffInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                fetchStaff()
            } else {
                const results = await filterObjectsByAllProperties(staff, value);
                setStaff(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };


    useEffect(() => {
        getCustomer()
        getUnitsForCustomer()
        fetchUnits()
        fetchFamilyMembers()
        fetchVehicles()
        fetchStaff()
    }, [])

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/core/dashboard/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/customer_management"}>Customer  Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>View Customer</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body py-0">
                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                <i className="ti ti-building-bank me-2"></i>Info
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                <i className="ti ti-building-warehouse me-2"></i>Units
                                            </a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-3" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                <i className="ti ti-olympics me-2"></i>Invoices
                                            </a>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-4" role="tab" aria-selected="true">
                                                <i className="ti ti-file-text me-2"></i>Documents
                                            </a>
                                        </li> */}



                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-6" data-bs-toggle="tab" href="#profile-6" role="tab" aria-selected="true">
                                                <i className="ti ti-user-plus me-2"></i>Family
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-7" data-bs-toggle="tab" href="#profile-7" role="tab" aria-selected="true">
                                                <i className="ti ti-car me-2"></i>Vehicles
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-8" data-bs-toggle="tab" href="#profile-8" role="tab" aria-selected="true">
                                                <i className="ti ti-users me-2"></i>Staff
                                            </a>
                                        </li>



                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab-5" data-bs-toggle="tab" href="#profile-5" role="tab" aria-selected="true">
                                                <i className="ti ti-settings me-2"></i>Settings
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                    <div className="row">
                                        <div className="col-lg-4 col-xxl-3">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="text-center mt-3">
                                                        <div className="chat-avtar d-inline-flex mx-auto">
                                                            <i className="ti ti-user me-2" style={{ fontSize: 100 }}></i>
                                                        </div>
                                                        <h5 className="mb-0"></h5>
                                                        <h2 className="text-muted mt-2">{customer.firstName} {customer.lastName}</h2>
                                                        {/* <h2 className="text-muted mt-2">{customer._id}</h2> */}
                                                        <hr className="my-3 border border-secondary-subtle" />

                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Email: </strong>{customer.email}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Phone No.: </strong>{customer.phoneNumber}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>ID No: </strong>{customer.idNumber}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Type: </strong>{customer.customerType}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            <p className="mb-0"><strong>Resident: </strong>{customer.residentType}</p>
                                                        </div>
                                                        <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            {/* <p className="mb-0"><strong>landRateNumber: </strong>1500 {defaultMeasurement.symbol}</p> */}
                                                        </div>
                                                        {/* <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                                            {
                                                                customer.isEnabled ?
                                                                    <button className="btn btn-danger btn-block mx-auto" onClick={handleDisableCustomer}>Disable Customer</button>
                                                                    :
                                                                    <button className="btn btn-primary btn-block mx-auto" onClick={handleEnableCustomer}>Enable Customer</button>
                                                            }
                                                        </div> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">

                                    <div className="card">

                                        <div className="card-header">

                                            <div>
                                                {/* <div style={{ float: 'right' }}>
                                                    <button className="btn btn-primary" onClick={addUnoccupiedUnit}>
                                                        <i className="ti ti-circle-plus me-2"></i> Add Unit</button>
                                                </div> */}
                                                <div style={{ float: 'right' }}>
                                                    <button className="btn btn-primary" onClick={() => {
                                                        setAddUnitDialogVisible(true)
                                                    }}><i className="ti ti-circle-plus me-2"></i> Add Unit</button>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="card-body">
                                            <div className="tab-content" id="nav-tabContent">

                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <DataTable value={customerUnits} emptyMessage="No units found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                <Column field="name" sortable header="Name"></Column>
                                                                <Column field="unitType" header="Type"></Column>
                                                                <Column field="floorUnitNo" header="Floor/Unit"></Column>
                                                                <Column field="lettableFloorArea" header="LFA"></Column>
                                                                <Column field="landRateNumber" header="LRN"></Column>
                                                                <Column field="grossArea" header="GA"></Column>
                                                                <Column field="netLettableArea" header="NLA"></Column>

                                                            </DataTable>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="tab-pane fade" id="profile-4" role="tabpanel" aria-labelledby="profile-tab-4">
                                    <div className="row">
                                        <div className="col-md-2" >
                                            <Link>
                                                <div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                    <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                    <strong className="mt-2">Tax Certificate</strong>

                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-md-2" >
                                            <Link><div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                <strong className="mt-2">Company Certificate</strong>

                                            </div></Link>
                                        </div>
                                        <div className="col-md-2" >
                                            <Link>
                                                <div className="card text-center" style={{ paddingTop: 25, paddingBottom: 20 }}>
                                                    <i className="ti ti-file-download" style={{ fontSize: 50 }}></i>
                                                    <strong className="mt-2">ID / Passport</strong>

                                                </div></Link>



                                        </div>

                                    </div>
                                </div>

                                <div className="tab-pane fade" id="profile-5" role="tabpanel" aria-labelledby="profile-tab-5">
                                    <div className="card">

                                        <div className="card-body py-0">
                                            <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">


                                                <li className="nav-item">
                                                    <a className="nav-link active" id="setting-tab-1" data-bs-toggle="tab" href="#setting-1" role="tab" aria-selected="true">
                                                        <i className="ti ti-edit me-2"></i>Edits
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="tab-content">

                                            <div className="tab-pane active" id="setting-1" role="tabpanel" aria-labelledby="setting-tab-1">
                                                <div className="row">
                                                    <div className="col-lg-4">

                                                    </div>

                                                    <div className="col-lg-4">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <b>Update Customer</b>
                                                            </div>

                                                            <div className="card-body" style={{ padding: '10px' }}>
                                                                <label>First Name<span style={{ color: 'red' }}>*</span>:</label>
                                                                <input className="form-control mt-2" type="text" placeholder="Enter unit name"
                                                                    value={firstName} onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        setFirstName(value)
                                                                    }}
                                                                ></input>
                                                            </div>

                                                            <div className="card-body" style={{ padding: '10px' }}>
                                                                <label>Last Name<span style={{ color: 'red' }}>*</span>:</label>
                                                                <input className="form-control mt-2" type="text" placeholder="Enter unit name"
                                                                    value={lastName} onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        setLastName(value)
                                                                    }}
                                                                ></input>
                                                            </div>

                                                            <div className="card-body" style={{ padding: '10px' }}>
                                                                <label>Phone Number<span style={{ color: 'red' }}>*</span>:</label>
                                                                <input className="form-control mt-2" type="text" placeholder="Enter unit name"
                                                                    value={phoneNumber} onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        setPhoneNumber(value)
                                                                    }}
                                                                ></input>
                                                            </div>




                                                            <div className='mt-4 mb-2' style={{ marginRight: '5px' }}>
                                                                <button className="btn btn-primary" onClick={() => { HandleCustomerUpdate() }} style={{ float: 'right' }}>Save Changes</button>
                                                            </div>


                                                        </div>


                                                    </div>
                                                    <div className="col-lg-4">

                                                    </div>

                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                </div>


                                <div className="tab-pane fade" id="profile-6" role="tabpanel" aria-labelledby="profile-tab-6">
                                    <div className="card">

                                        <div className="tab-content">

                                            <div className="tab-pane active" id="setting-1" role="tabpanel" aria-labelledby="setting-tab-1">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h5>Family</h5>
                                                                    </div>
                                                                    <div className='card-body'>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-xxl-12">
                                                                                <div className="card">
                                                                                    <div className="card-body position-relative">
                                                                                        <div className="row">
                                                                                            <div className="col-md-3 col-xs-12 mb-3">
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    placeholder="Search here"
                                                                                                    value={searchTerm}
                                                                                                    onChange={(e) => { handleFamilyInputSearch(e.target.value) }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="col-md-9 col-xs-12 mb-3">
                                                                                                <div style={{ float: 'right' }}>
                                                                                                    <button className='btn btn-primary' onClick={() => openDialog('addFamily', customer)}>Add Family Member</button>
                                                                                                    &nbsp;
                                                                                                    {/* <button className='btn btn-primary' onClick={() => navigate('/app/facility/customer_management/import_customers')}>Import Customers</button> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className="dt-responsive table-responsive">
                                                                                                    <DataTable
                                                                                                        value={familyMembers}
                                                                                                        emptyMessage="No family members found."
                                                                                                        sortMode="multiple"
                                                                                                        paginator
                                                                                                        rows={5}
                                                                                                        stripedRows
                                                                                                        tableStyle={{ minWidth: '50rem' }}
                                                                                                    >
                                                                                                        <Column field="name" header="Name" />
                                                                                                        <Column body={phoneNumberTemplate} header="Phone Number"></Column>
                                                                                                        <Column field="relation" header="Relationship" />
                                                                                                        <Column body={qrCodeTemplate} header="QRCode" />
                                                                                                    </DataTable>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>


                                <div className="tab-pane fade" id="profile-7" role="tabpanel" aria-labelledby="profile-tab-7">
                                    <div className="card">

                                        <div className="tab-content">

                                            <div className="tab-pane active" id="setting-1" role="tabpanel" aria-labelledby="setting-tab-1">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h5>Vehicles</h5>
                                                                    </div>
                                                                    <div className='card-body'>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-xxl-12">
                                                                                <div className="card">
                                                                                    <div className="card-body position-relative">
                                                                                        <div className="row">
                                                                                            <div className="col-md-3 col-xs-12 mb-3">
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    placeholder="Search here"
                                                                                                    value={searchTerm}
                                                                                                    onChange={(e) => { handleVehicleInputSearch(e.target.value) }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="col-md-9 col-xs-12 mb-3">
                                                                                                <div style={{ float: 'right' }}>
                                                                                                    <button className='btn btn-primary' onClick={() => openDialog('addVehicle', customer)}>Add Vehicle</button>
                                                                                                    &nbsp;
                                                                                                    {/* <button className='btn btn-primary' onClick={() => navigate('/app/facility/customer_management/import_customers')}>Import Customers</button> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className="dt-responsive table-responsive">
                                                                                                    <DataTable
                                                                                                        value={vehicles}
                                                                                                        emptyMessage="No vehicles found."
                                                                                                        sortMode="multiple"
                                                                                                        paginator
                                                                                                        rows={5}
                                                                                                        stripedRows
                                                                                                        tableStyle={{ minWidth: '50rem' }}
                                                                                                    >
                                                                                                        <Column field="name" header="Name" />
                                                                                                        <Column field="model" header="Model" />
                                                                                                        <Column field="plateNumber" header="Plate Number" />
                                                                                                        <Column body={qrCodeVehicleTemplate} header="QRCode" />
                                                                                                    </DataTable>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>


                                <div className="tab-pane fade" id="profile-8" role="tabpanel" aria-labelledby="profile-tab-8">
                                    <div className="card">

                                        <div className="tab-content">

                                            <div className="tab-pane active" id="setting-1" role="tabpanel" aria-labelledby="setting-tab-1">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h5>Staff</h5>
                                                                    </div>
                                                                    <div className='card-body'>
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-xxl-12">
                                                                                <div className="card">
                                                                                    <div className="card-body position-relative">
                                                                                        <div className="row">
                                                                                            <div className="col-md-3 col-xs-12 mb-3">
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    placeholder="Search here"
                                                                                                    value={searchTerm}
                                                                                                    onChange={(e) => { handleStaffInputSearch(e.target.value) }}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="col-md-9 col-xs-12 mb-3">
                                                                                                <div style={{ float: 'right' }}>
                                                                                                    <button className='btn btn-primary' onClick={() => openDialog('addStaff', customer)}>Add Staff Member</button>
                                                                                                    &nbsp;
                                                                                                    {/* <button className='btn btn-primary' onClick={() => navigate('/app/facility/customer_management/import_customers')}>Import Customers</button> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-md-12">
                                                                                                <div className="dt-responsive table-responsive">
                                                                                                    <DataTable
                                                                                                        value={staff}
                                                                                                        emptyMessage="No staff members found."
                                                                                                        sortMode="multiple"
                                                                                                        paginator
                                                                                                        rows={5}
                                                                                                        stripedRows
                                                                                                        tableStyle={{ minWidth: '50rem' }}
                                                                                                    >
                                                                                                        <Column field="name" header="Full Name" />
                                                                                                        <Column field="jobRole" header="Job" />
                                                                                                        <Column body={phoneNumberTemplate} header="Phone Number"></Column>
                                                                                                        <Column body={qrCodeStaffTemplate} header="QRCode" />
                                                                                                    </DataTable>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                header={'QR Code'}
                visible={visibleQRCode}
                onHide={() => {
                    if (visibleQRCode) setVisibleQRCode(false);
                }}
            >
                <div className="text-center mt-4">
                    <div ref={qrCodeRef}>
                        <QRCode value={JSON.stringify(selectedQRCode)} />
                    </div>
                    <br />
                    <br />
                    <p style={{ color: 'white', backgroundColor: 'black' }}>No: {customer.customerNumber}</p>
                    <p style={{ color: 'white', backgroundColor: 'black' }}>POWERED BY PAYSERVE.CO.kE</p>
                    <button className="btn btn-primary mt-3" onClick={handleDownloadQRCode}>
                        Download QR Code
                    </button>
                </div>


            </Dialog>
            <Dialog
                header={'Add Unit To Customer'}
                visible={addUnitDialog}
                style={{ width: '30vw' }}
                onHide={() => {
                    if (addUnitDialog) setAddUnitDialogVisible(false);
                }}
            >
                <div className="row">
                    <div className="col-md-12 mt-3">


                        <div className="mb-3">
                            <label className="form-label">Unit<span style={{ color: 'red' }}>*</span></label>
                            <Autocomplete
                                multiple // Enable multiple select
                                options={units}
                                getOptionLabel={(option) => option.name} // Define how each option is displayed
                                value={selectedUnits}
                                onChange={(event, newValue) => setSelectedUnits(newValue)} // Handle selection
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Units"
                                        value={units}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            console.log(value)
                                            setUnits(value)
                                        }}
                                        variant="outlined" />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={option._id}
                                            label={option.name}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                } // Display selected units as chips
                            />
                        </div>


                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary" onClick={addUnoccupiedUnit}>
                                Submit
                            </button>
                        </div>

                    </div>

                </div>



            </Dialog>


            <ReusableDialog
                header={
                    dialogType === 'addFamily' ? 'Add Family Member' :
                        dialogType === 'addVehicle' ? 'Add Vehicle' :
                            'Add Staff Memeber'
                }
                visible={dialogVisible}
                onHide={() => setDialogVisible(false)}
                submitAction={handleSubmit}
            >
                {dialogType === 'addFamily' && (
                    <div>
                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="name"
                                value={name}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setName(value)
                                }}
                            ></input>
                        </div>


                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="Phone number"
                                value={phone}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setPhone(value)
                                }}
                            ></input>
                        </div>


                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="Relation"
                                value={relation}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setRelation(value)
                                }}
                            ></input>
                        </div>
                    </div>
                )}

                {dialogType === 'addVehicle' && (
                    <div>
                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="name"
                                value={name}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setName(value)
                                }}
                            ></input>
                        </div>


                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="model"
                                value={model}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setModel(value)
                                }}
                            ></input>
                        </div>


                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="Plate number"
                                value={plateNumber}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setPlateNumber(value)
                                }}
                            ></input>
                        </div>
                    </div>
                )}

                {dialogType === 'addStaff' && (
                    <div>
                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="name"
                                value={name}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setName(value)
                                }}
                            ></input>
                        </div>


                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="Job"
                                value={job}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setJob(value)
                                }}
                            ></input>
                        </div>


                        <div className="mb-3">
                            <input className="form-control mt-2" type="text" placeholder="Phone number"
                                value={phone}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    setPhone(value)
                                }}
                            ></input>
                        </div>

                    </div>
                )}
                <div className="mb-3">
                    <input type="checkbox" placeholder="name"
                        value={visitorQRCode}
                        checked={visitorQRCode}
                        onChange={(e) => {

                            setVisitorQRCode(!visitorQRCode)

                        }}
                    ></input>
                    &nbsp;&nbsp;
                    <label>Generate {dialogType === 'addVehicle' ? `Vehicle Access` : `Visitor Access`} QR Code</label>
                </div>
            </ReusableDialog>


        </Layout>
    );
};






export default ViewCustomer;
