import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Layout from '../component/layout'
import { useNavigate } from 'react-router-dom';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getUnitsForFacility } from '../../../utils/urls';
import { getItem } from '../../../utils/localStorage'
import { AddNewCustomerURL } from '../../../utils/urls';


function AddNewCustomer() {
    const navigate = useNavigate()

    // const [units, setUnits] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [nextOfKinName, setNextOfKinName] = useState('');
    const [nextOfKinRelationship, setNextOfKinRelationship] = useState('');
    const [nextOfKinContact, setNextOfKinContact] = useState('');
    const [units, setUnits] = useState([]);
    const [customerType, setCustomerType] = useState('');
    const [residentType, setResidentType] = useState('');

    const fetchUnits = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${facilityId}`, 'GET', {});
            if (response.success) {
                const non_occupied_units = response.data.filter((x) => {
                    return x.homeOwnerId === undefined && x.tenantId === undefined
                })

                setUnits(non_occupied_units);
            } else {
                throw new Error('Error fetching units')
            }
        } catch (err) {
            console.error('Error fetching units:', err);
        }
    };

    const HandleCustomerSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const customerData = {
                firstName,
                lastName,
                email,
                phoneNumber,
                idNumber,
                nextOfKinName,
                nextOfKinRelationship,
                nextOfKinContact,
                units: selectedUnits,
                customerType,
                residentType
            };


            if (!customerData.firstName) throw new Error('First name is required.');
            if (!customerData.lastName) throw new Error('Last name is required.');
            if (!customerData.email) throw new Error('Email is required.');
            if (!customerData.phoneNumber) throw new Error('Phone number is required.');
            if (!customerData.idNumber) throw new Error('ID number is required.');
            if (!customerData.units.length === 0) throw new Error('Select atleast one unit')
            if (!customerData.customerType) throw new Error('Customer Type is required.');
            if (!customerData.residentType) throw new Error('Resident Type is required.');
            if (!customerData.nextOfKinName) throw new Error('Next of kin name is required.');
            if (!customerData.nextOfKinRelationship) throw new Error('Next of kin relationship is required.');
            if (!customerData.nextOfKinContact) throw new Error('Next of kin contact is required.');


            const response = await makeRequest2(`${AddNewCustomerURL}/${facilityId}`, 'POST', customerData);

            if (response.success) {
                toastify('Customer added successfully', 'success');
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
                setIdNumber('');
                setNextOfKinName('');
                setNextOfKinRelationship('');
                setNextOfKinContact('');
                setSelectedUnits([]);
                setCustomerType('');
                setResidentType('');
            }
            else {
                throw new Error(response.error);
            }

        } catch (err) {
            console.log(err)
            toastify(err.message, 'error')
        }
    }

    const handleClear = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setIdNumber('');
        setNextOfKinName('');
        setNextOfKinRelationship('');
        setNextOfKinContact('');
        setUnits([]);
        setCustomerType('');
        setResidentType('');
    };



    useEffect(() => {
        fetchUnits();
    }, []);


    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/customer_management"}>Customer Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/customer_management/add_new_customer"}>Add New Customer</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">

                            <div className="card">
                                <div className="card-header">
                                    <Link to={"#"} onClick={() => navigate(-1)}><span><i className="ti ti-arrow-narrow-left"></i> Back</span></Link>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="container">
                                                
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h5>Customer Info</h5>
                                                    </div>

                                                    <div className="card-body">
                                                        <div className='container'>
                                                            <div className='row'>
                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">First Name<span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={firstName}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setFirstName(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={lastName}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setLastName(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Email<span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="email"
                                                                            className="mb-3 form-control"
                                                                            value={email}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setEmail(value)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Mobile Number<span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            placeholder="e.g. +254 712 345 678"
                                                                            value={phoneNumber}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setPhoneNumber(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">ID Number / Passport Number<span style={{ color: 'red' }}>*</span></label>
                                                                        <input
                                                                            type="text"
                                                                            className="mb-3 form-control"
                                                                            value={idNumber}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                setIdNumber(value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>



                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Unit<span style={{ color: 'red' }}>*</span></label>
                                                                        <Autocomplete
                                                                            multiple // Enable multiple select
                                                                            options={units}
                                                                            getOptionLabel={(option) => option.name} // Define how each option is displayed
                                                                            value={selectedUnits}
                                                                            onChange={(event, newValue) => setSelectedUnits(newValue)} // Handle selection
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} label="Select Units"
                                                                                    value={units}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        console.log(value)
                                                                                        setUnits(value)
                                                                                    }}
                                                                                    variant="outlined" />
                                                                            )}
                                                                            renderTags={(value, getTagProps) =>
                                                                                value.map((option, index) => (
                                                                                    <Chip
                                                                                        key={option._id}
                                                                                        label={option.name}
                                                                                        {...getTagProps({ index })}
                                                                                    />
                                                                                ))
                                                                            } // Display selected units as chips
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label>Customer Type<span style={{ color: 'red' }}>*</span></label>
                                                                        <select
                                                                            className="form-control mt-2"
                                                                            value={customerType}
                                                                            onChange={(e) => setCustomerType(e.target.value)}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            <option value="home owner">Home Owner</option>
                                                                            <option value="tenant">Tenant</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label>Resident Type<span style={{ color: 'red' }}>*</span></label>
                                                                        <div className="form-check mt-2">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                value="resident"
                                                                                checked={residentType === "resident"}
                                                                                onChange={(e) => setResidentType(e.target.value)}
                                                                            />
                                                                            <label className="form-check-label">Resident</label>
                                                                        </div>
                                                                        <div className="form-check mt-2">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name="residentType"
                                                                                value="non-resident"
                                                                                checked={residentType === "non-resident"}
                                                                                onChange={(e) => setResidentType(e.target.value)}
                                                                            />
                                                                            <label className="form-check-label">Non-Resident</label>
                                                                        </div>
                                                                    </div>
                                                                </div>




                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                               

                                                <div className='col-sm-12'>
                                                    <div className='card'>
                                                        <div className="card-header">
                                                            <h5>Next of Kin</h5>
                                                        </div>
                                                        <div className='card-body'>
                                                            <div className='container'>
                                                                <div className='row'>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Full Name<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="mb-3 form-control"
                                                                                value={nextOfKinName}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setNextOfKinName(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Relationship<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="mb-3 form-control"
                                                                                value={nextOfKinRelationship}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setNextOfKinRelationship(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Contact<span style={{ color: 'red' }}>*</span></label>
                                                                            <input
                                                                                type="text"
                                                                                className="mb-3 form-control"
                                                                                value={nextOfKinContact}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value;
                                                                                    setNextOfKinContact(value)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-end">
                                                    <button type="button" className="btn btn-outline-secondary" onClick={handleClear}>Clear</button>
                                                    &nbsp;
                                                    <button type="button" className="btn btn-primary" onClick={HandleCustomerSubmit}>Submit</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default AddNewCustomer