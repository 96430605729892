import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import QrReader from 'react-qr-scanner';
import { Scanner } from '@yudiel/react-qr-scanner';
import Layout from '../../component/layout';
import { Button } from 'primereact/button';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import { confirmQRDataURL, getEntriesAndExitsForFacility } from '../../../../utils/urls';
import { getItem } from '../../../../utils/localStorage';

function ScanQRCode() {
    const [delay, setDelay] = useState(0); // Initially no scanning
    const [result, setResult] = useState('');
    const [scanning, setScanning] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false); // Controls whether scanning is active
    const [facingMode, setFacingMode] = useState('rear'); // Default to rear camera
    const [entries, setEntries] = useState([])
    const [entry, setEntry] = useState('');

    const handleScan = async (data) => {
        try {

            if(entry === ''){
               throw new Error('Entry is required.')
            }
            else
            {
                if (data) {
                    const response = await makeRequest2(confirmQRDataURL, 'POST', {
                        data,entry
                    });
                    if (response.success) {
                        toastify('Visitor / Vehicle is allowed', 'success');
                        setScanning(false); // Stop scanning when a result is found
                        setDelay(0);
                        setEntry('')
                    } else {
                        toastify(response.error, 'error');
                    }
                }
            }
            
        } catch (err) {
            setScanning(false);
            toastify(err.message, 'error');
        }
    };

    const handleError = (err) => {
        toastify(err, 'error');
    };

    const handleStartScan = () => {
        if(entry === ''){
            toastify('Entry is required', 'error');
        }
        else
        {
            setDelay(300); // Set delay to start scanning
            setScanning(true); // Enable scanning mode
            setError(false);
            setSuccess(false);
        }
        
    };

    const handleCancelScan = () => {
        setScanning(false); // Stop scanning
        setDelay(0); // Reset delay to stop the scanner
        setError(false);
        setSuccess(false);
        setEntry('')
    };

    const toggleCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'rear' ? 'front' : 'rear'));
    };

    // Updated previewStyle for larger camera and centered positioning
    const previewStyle = {
        height: 400, // Increased height
        width: 600,  // Increased width
        display: 'block',
        margin: '0 auto', // Center the scanner
    };
    const fetchEntriesAndExitsForFacility = async () => {
        try {

            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setEntries(response.data);
            } else {
                throw new Error('Failed to fetch entries and exits')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };
    useEffect(() => {
        fetchEntriesAndExitsForFacility();

    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Scan QR Code</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Scan QR Code</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">

                                                <div className="card-body position-relative ">
                                                    <div className="row">
                                                        
                                                        <div className="col-sm-4">
                                                            <div className="mb-3">
                                                                <label>Entry Point<span style={{ color: 'red' }}>*</span></label>
                                                                <select
                                                                     className="form-control mt-2"
                                                                    value={entry}
                                                                    onChange={(e) => setEntry(e.target.value)}
                                                                >
                                                                    <option value=""> -- Select --</option>
                                                                    {
                                                                        entries.map((entry) => {
                                                                            return entry.purpose === 'entry' || entry.purpose === 'entry/exit' && !entry.disabled && <option value={entry.name}>{entry.name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                       

                                                    </div>
                                                    {scanning ? (
                                                        <>
                                                            <Scanner
                                                                delay={delay}
                                                                styles={previewStyle} // Larger and centered camera preview
                                                                onError={handleError}
                                                                onScan={handleScan} // Use the current camera mode ('front' or 'rear')
                                                            />
                                                            <Button
                                                                label="Cancel Scanning"
                                                                onClick={handleCancelScan}
                                                                className="p-button-danger mt-3"
                                                            />
                                                            {/* <Button
                                                                label={`Switch to ${facingMode === 'rear' ? 'Front' : 'Rear'} Camera`}
                                                                onClick={toggleCamera}
                                                                className="p-button-secondary mt-3"
                                                            /> */}
                                                        </>
                                                    ) : (
                                                        <p>Click "Scan Now" to start scanning.</p>
                                                    )}
                                                    {error && (
                                                        <div className="text-center">
                                                            <strong style={{ color: 'red', fontSize: 20 }}>
                                                                Error Scanning!!
                                                            </strong>
                                                        </div>
                                                    )}
                                                    {success && (
                                                        <div className="text-center">
                                                            <strong style={{ color: 'green', fontSize: 20 }}>
                                                                Visitor is allowed!
                                                            </strong>
                                                        </div>
                                                    )}
                                                    {!scanning && (
                                                        <Button
                                                            label="Scan Now"
                                                            onClick={handleStartScan}
                                                            className="p-button-primary mt-3"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ScanQRCode;
