import React, { useState, useEffect } from 'react'
import Layout from '../../component/layout'
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { getItem, setItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { filterObjectsByAllProperties } from '../../../../utils/filterSearch';
import { getVisitorsForFacility, getUnitsForFacility, getCustomersURL, getCustomerUnits, requestVisitConfirmationURL, getEntriesAndExitsForFacility, deleteVisitorURL } from '../../../../utils/urls';


function VisitorList() {


    const [visitors, setVisitors] = useState([]);
    const [units, setUnits] = useState([]);
    const [customers, setCustomers] = useState([]);


    const [houseNumber, setHouseNumber] = useState('');
    const [resident, setResident] = useState('');
    const [carRegistration, setCarRegistration] = useState('');
    const [carMake, setCarMake] = useState('');
    const [carColor, setCarColor] = useState('');
    const [carOccupants, setCarOccupants] = useState('');
    const [entryexists, setEntryExit] = useState([])
    const [entry, setEntry] = useState('')


    const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [selectedVisitor, setSelectedVisitor] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');

    const fetchVisitors = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId')
            const response = await makeRequest2(getVisitorsForFacility + '/' + facilityId, 'GET', {});

            if (response.success) {
                setVisitors(response.data.data);
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toastify(err.message, 'error')
        }
    };

    const handleDeleteVisitor = async (visitorId) => {
        try {
            const response = await makeRequest2(`${deleteVisitorURL}/${visitorId}`, 'GET', {});
            if (response.success) {
                toastify('Visitor deleted successfully', 'success');
                fetchVisitors()
            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button
                    icon="ti ti-row-insert-top"
                    className="p-button-rounded p-button-info mr-2"
                    onClick={() => { handleActionClick(rowData) }}
                    tooltip="Edit"
                />
                <Button
                    icon="ti ti-trash"
                    className="p-button-rounded p-button-danger mr-2"
                    onClick={() => {
                        handleDeleteVisitor(rowData._id)

                    }}
                    tooltip="Delete"

                />
            </div>
            // <React.Fragment>
            //     <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text" onClick={() => handleActionClick(rowData)} />
            // </React.Fragment>
        );
    };

    const fetchUnitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setUnits(response.data); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const fetchCustomersForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getCustomersURL}/${value}`, 'GET', {});

            if (response.success) {
                setCustomers(response.data);
            } else {
                throw new Error('Failed to fetch customers')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const handleActionClick = (rowData) => {
        setSelectedVisitor(rowData);
        setConfirmDialogVisible(true);
        setResident('');
        setUnits([]);
        setCarRegistration('');
        setCarColor('');
        setCarMake('');
        setCarOccupants('')
    };
    const fetchEntriesAndExitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setEntryExit(response.data);
            } else {
                throw new Error('Failed to fetch entries and exits')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const handleSubmitClick = async () => {
        try {
            const formData = {
                resident,
                houseNumber,
                carRegistration,
                carColor,
                carMake,
                carOccupants,
                visitor: selectedVisitor,
                entry
            };

            if (resident === '') {
                throw new Error('Resident is required')
            }
            else if (houseNumber === '') {
                throw new Error('Unit is required')
            }
            else if (entry === '') {
                throw new Error('Entry Point is required')
            }
            const response = await makeRequest2(requestVisitConfirmationURL, 'POST', formData)
            if (response.success) {
                toastify('Visitation Request has been sent successfully.', 'success');
                setConfirmDialogVisible(false);
                setResident('');
                setUnits([]);
                setCarRegistration('');
                setCarColor('');
                setCarMake('');
                setCarOccupants('')


            }
            else {
                throw new Error(response.error)
            }
        }
        catch (err) {
            toastify(err.message, 'error')
        }



    };

    const handleInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                fetchVisitors()
            } else {
                const results = await filterObjectsByAllProperties(visitors, value);
                setVisitors(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };
    const phoneNumberTemplate = (rowData) => {
        const phoneNumber = rowData.phoneNumber;
        return '+254' + phoneNumber
    }
    const getUnitsForCustomer = async (customerId) => {
        try {
            console.log(`Fetching units for customerId: ${customerId}`);
            const response = await makeRequest2(`${getCustomerUnits}/${customerId}`, 'GET', {});
            setUnits(response.data || []);
            if (response.data.length > 0) {
                setHouseNumber(response.data[0].name);
            }
            // Ensure response.data is set to an array
        } catch (error) {
            toastify('Error fetching units: ' + error.message);
        }
    };


    useEffect(() => {
        fetchVisitors();
        fetchCustomersForFacility()
        fetchUnitsForFacility()
        fetchEntriesAndExitsForFacility()
    }, []);


    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor List</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">

                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">

                                                        <div className="col-lg-12 col-xxl-12">
                                                            <div className="card">
                                                                <div className="card-body position-relative">

                                                                    <div className="col-md-3 col-xs-12 mb-3">
                                                                        <input
                                                                            className="form-control"
                                                                            placeholder="Search here"
                                                                            value={searchTerm}
                                                                            onChange={(e) => { handleInputSearch(e.target.value) }}
                                                                        />
                                                                    </div>

                                                                    <div className="dt-responsive table-responsive mt-5">
                                                                        <DataTable value={visitors} emptyMessage="No visitors found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>


                                                                            <Column header="Visitor Name" body={(rowData) => `${rowData.firstName} ${rowData.lastName}`}></Column>

                                                                            <Column body={phoneNumberTemplate} header="Phone Number"></Column>
                                                                            <Column field="idNumber" header="ID No"></Column>
                                                                            <Column body={actionBodyTemplate} header="Action"></Column>

                                                                        </DataTable>


                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <Dialog
                header={'Request Visit Confirmation'}
                visible={confirmDialogVisible}
                style={{ width: '30vw' }}
                onHide={() => {
                    if (confirmDialogVisible) setConfirmDialogVisible(false);
                }}
            >

                <div className="row">
                    <div className="col-md-12 mt-3">


                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card-body">
                                    <div className="container">

                                        <div className="card">
                                            <div className="card-header">
                                                <h5>Visitor Information</h5>
                                            </div>

                                            <div className="card-body">
                                                <div className='container'>
                                                    <div className='row'>






                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>Resident</label>
                                                                <select
                                                                    className="form-control mt-2"
                                                                    value={resident}
                                                                    onChange={(e) => {
                                                                        setResident(e.target.value);
                                                                        getUnitsForCustomer(e.target.value);


                                                                    }}
                                                                >
                                                                    <option value="">Select Resident</option>
                                                                    {customers.map((customer) => (
                                                                        customer.residentType === 'resident' &&

                                                                        <option key={customer._id} value={customer._id}>
                                                                            {customer.firstName + ' ' + customer.lastName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <div className="mb-3">
                                                                <label>Unit</label>
                                                                <select
                                                                    className="form-control mt-2"
                                                                    value={houseNumber}
                                                                    onChange={(e) => setHouseNumber(e.target.value)}
                                                                    disabled={resident === '' & false} // Disable if no resident is selected
                                                                >
                                                                    <option value="">-- Select --</option>

                                                                    {
                                                                        resident !== '' &&
                                                                        <>
                                                                            {units.map((unit) => (
                                                                                <option key={unit._id} value={unit.name}>
                                                                                    {unit.name}
                                                                                </option>
                                                                            ))}</>

                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className='row'>






                                                        <div className="col-sm-12">
                                                            <div className="mb-3">
                                                                <label>Entry Point</label>
                                                                <select
                                                                    className="form-control mt-2"
                                                                    value={entry}
                                                                    onChange={(e) => {
                                                                        setEntry(e.target.value)


                                                                    }}
                                                                >
                                                                    <option value="">-- Select --</option>
                                                                    {
                                                                        entryexists.map((x) => {
                                                                            if (x.purpose === 'entry/exit' || x.purpose === 'entry'  ) {
                                                                                if(x.disabled === false){
                                                                                    return <option value={x.name}>{x.name} {x.disabled} </option>
                                                                                }
                                                                                
                                                                            }
                                                                        })
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-sm-12'>
                                            <div className='card'>
                                                <div className="card-header">
                                                    <h5>Vehicle Details</h5>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='container'>
                                                        <div className='row'>

                                                            <div className="col-sm-6">
                                                                <div className="mb-3">
                                                                    <label>Vehicle Reg Number</label>
                                                                    <input className="form-control mt-2" type="text" placeholder="Vehicle reg no"
                                                                        value={carRegistration} onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            setCarRegistration(value)
                                                                        }}
                                                                    ></input>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Vehicle Model</label>
                                                                    <input
                                                                        type="text"
                                                                        className="mb-3 form-control"
                                                                        value={carMake}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            setCarMake(value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Vehicle Color</label>
                                                                    <input
                                                                        type="text"
                                                                        className="mb-3 form-control"
                                                                        value={carColor}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            setCarColor(value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">No of Occupants</label>
                                                                    <input
                                                                        type="text"
                                                                        className="mb-3 form-control"
                                                                        value={carOccupants}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            setCarOccupants(value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className='mt-4' style={{ float: 'right' }}>
                            <button className="btn btn-primary"
                                onClick={handleSubmitClick}
                            >
                                Submit
                            </button>
                        </div>

                    </div>

                </div>



            </Dialog>

        </Layout>
    )
}

export default VisitorList