import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Layout from '../component/layout'
import { makeRequest2 } from '../../../utils/makeRequest'
import { toastify } from '../../../utils/toast'
import { getListOfFacilities } from '../../../utils/urls'
import { setItem, getItem } from '../../../utils/localStorage'


function Facilities() {
    const navigate = useNavigate()
    const [facilities, setFacilities] = useState([])

    const getFacilities = async () => {
        try {
            const response = await makeRequest2(getListOfFacilities, 'GET', {});
            console.log(response)
            if (response.success) {
                setFacilities(response.data)
            }
        }
        catch (err) {
            console.log(err.message)
            toastify('Server error.', 'error')
        }
    }



    const handleFacilityClick = async (facility) => {
     
        await setItem('selectedFacilityId', facility._id)
        const data = await getItem('selectedFacilityId')
        navigate('/facility/', { state: { facility } })
    }

    useEffect(() => {
        getFacilities()
    }, [])

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="javascript: void(0)">Facilities</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                {/* <div className="card-header">
                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ float: 'right' }}>
                                <button className="btn btn-primary" onClick={() => navigate('/app/add_new_facility')}>
                                    <i className="ti ti-plus pt-3"></i> Add New Facility
                                </button>
                            </div>
                        </div>
                    </div>
                </div>  */}
                <div className="card-body">
                    <div className="row">
                        {facilities.length === 0 ? (
                            <div className="col-md-12 text-center">
                                <p>No facilities available</p>
                            </div>
                        ) : (
                            facilities.map((facility, index) => (
                                <div className="col-md-3" key={index}>
                                    <div className="pc-component">
                                        <div className="card mb-2 text-center" style={{ position: 'relative' }}>
                                            <span className="badge bg-warning" style={{ position: 'absolute', top: 5, left: 5 }}>{index + 1}</span>
                                            <span className="badge bg-light-primary"> <i className="ti ti-home f-50 mt-3"></i></span>

                                            <div className="card-body" onClick={() => handleFacilityClick(facility)} style={{ cursor: 'pointer' }}>
                                                <h5 className="card-title">{facility.name}</h5>
                                                <p className="card-text"><span className="badge bg-light-primary">{facility.location}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Facilities
