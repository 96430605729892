
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../../component/layout'
import { getItem } from '../../../../utils/localStorage';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { visitorPreRegistrationURL, getUnitsForFacility, getCustomersURL, getCustomerUnits,getEntriesAndExitsForFacility } from '../../../../utils/urls';


function GateRegistration() {

    const selectedFacilityId = getItem('selectedFacilityId');
    const facilityId = selectedFacilityId ? selectedFacilityId : null;

    const [units, setUnits] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [entries,setEntries] = useState([])

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [carRegistration, setCarRegistration] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [resident, setResident] = useState('');
    const [carMake, setCarMake] = useState('');
    const [carColor, setCarColor] = useState('');
    const [carOccupants, setCarOccupants] = useState('');
    const [visitorType, setVisitorType] = useState('');
    const [entry, setEntry] = useState('');
    const [confirmVisit, setConfirmVisit] = useState(false)


    useEffect(() => {
        if (resident) {
            // Fetch units for the selected resident when resident changes
            getUnitsForCustomer(resident);
        }
    }, [resident]);

    const getUnitsForCustomer = async (customerId) => {
        try {
            console.log(`Fetching units for customerId: ${customerId}`);
            const response = await makeRequest2(`${getCustomerUnits}/${customerId}`, 'GET', {});
            setUnits(response.data || []); 
            if(response.data.length > 0){
                setHouseNumber(response.data[0].name)
            } // Ensure response.data is set to an array
        } catch (error) {
            toastify('Error fetching units: ' + error.message);
        }
    };


    const fetchUnitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${value}`, 'GET', {});

            if (response.success) {
                setUnits(response.data); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const fetchCustomersForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getCustomersURL}/${value}`, 'GET', {});

            if (response.success) {
                setCustomers(response.data);
            } else {
                throw new Error('Failed to fetch customers')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };

    const fetchEntriesAndExitsForFacility = async () => {
        try {
          const value = await getItem('selectedFacilityId')
          const response = await makeRequest2(`${getEntriesAndExitsForFacility}/${value}`, 'GET', {});
    
          if (response.success) {
            setEntries(response.data);
          } else {
            throw new Error('Failed to fetch entries and exits')
          }
        } catch (error) {
          console.log(error.message)
          toastify(error.message, 'error');
        }
      };

    const handleVisitorSubmit = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const visitorData = {
                firstName,
                lastName,
                phoneNumber,
                idNumber,
                carRegistration,
                houseNumber,
                carMake,
                carColor,
                carOccupants,
                visitorType,
                resident,
                confirmVisit,
                entry
            };


            if (!visitorData.firstName) throw new Error('First name is required.');
            if (!visitorData.lastName) throw new Error('Last name is required.');
            if (!visitorData.phoneNumber) throw new Error('Phone number is required.');
       


            const response = await makeRequest2(visitorPreRegistrationURL + '/' + facilityId, 'POST', visitorData);

            if (response.success) {
                toastify('Visitor added successfully', 'success');
                setFirstName('');
                setLastName('');
                setPhoneNumber('');
                setIdNumber('');
                setCarRegistration('');
                setHouseNumber('');
                setCarMake('');
                setCarColor('');
                setCarOccupants('');
                setVisitorType('');
                setResident('');
            }
            else {
                throw new Error(response.error);
            }

        } catch (err) {
            toastify(err.message, 'error')
        }
    }

    const handleClear = () => {
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setIdNumber('');
        setCarRegistration('');
        setHouseNumber('');
        setCarMake('');
        setCarColor('');
        setCarOccupants('');
        setVisitorType('');
        setResident('');
        setConfirmVisit('');
    }

    useEffect(() => {
        fetchEntriesAndExitsForFacility();
        fetchUnitsForFacility();
        fetchCustomersForFacility();
    }, []);


    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
            <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
            <Button type="button" label="PDF" severity="warning" rounded onClick={() => { }} data-pr-tooltip="PDF" />
        </div>
    );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Visitor & Access Management</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"#"}>Gate Registration</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="card">
                <div className="card-header">
                    <h5>Gate Registration</h5>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card-body">
                            <div className="container">

                                <div className="card">
                                    <div className="card-header">
                                        <h5>Visitor Information</h5>
                                    </div>

                                    <div className="card-body">
                                        <div className='container'>
                                            <div className='row'>
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>First Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="First name"
                                                            value={firstName} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setFirstName(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Last Name<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="Last name"
                                                            value={lastName} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setLastName(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Phone Number<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="Phone number"
                                                            value={phoneNumber} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setPhoneNumber(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div>
{/* 
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>ID Number<span style={{ color: 'red' }}>*</span></label>
                                                        <input className="form-control mt-2" type="text" placeholder="ID number"
                                                            value={idNumber} onChange={(e) => {
                                                                let value = e.target.value;
                                                                setIdNumber(value)
                                                            }}
                                                        ></input>
                                                    </div>
                                                </div> */}

                                                {/* <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Visitor Type<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            className="form-control mt-2"
                                                            value={visitorType}
                                                            onChange={(e) => setVisitorType(e.target.value)}
                                                        >
                                                            <option value="">-- Select --</option>
                                                            <option value="Staff">Staff</option>
                                                            <option value="Service Providers">Delivery Personnel / Cab Drivers / Garbage Collector</option>
                                                            <option value="Government Officials/Agents">Government Officials / Agents</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                                {/* <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Entry Point<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            className="form-control mt-2"
                                                            value={entry}
                                                            onChange={(e) => setEntry(e.target.value)}
                                                        >
                                                            <option value=""> -- Select --</option>
                                                            {
                                                                entries.map((entry)=>{
                                                                    return entry.purpose === 'entry' || entry.purpose ==='entry/exit' && !entry.disabled && <option value={entry.name}>{entry.name}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Select Resident</label>
                                                        <select
                                                            className="form-control mt-2"
                                                            value={resident}
                                                            onChange={(e) => setResident(e.target.value)}
                                                        >
                                                            <option value="">-- Select --</option>
                                                            {customers.map((customer) => (
                                                                customer.residentType === 'resident' &&


                                                                <option key={customer._id} value={customer._id}>
                                                                    {customer.firstName + ' ' + customer.lastName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6">
                                                    <div className="mb-3">
                                                        <label>Unit</label>
                                                        <select
                                                            className="form-control mt-2"
                                                            value={houseNumber}
                                                            onChange={(e) => setHouseNumber(e.target.value)}
                                                            disabled={resident === '' & false} // Disable if no resident is selected
                                                        >
<option value="">-- Select --</option>

                                                            {
                                                                resident !== '' &&
                                                                <>
                                                                    {units.map((unit) => (
                                                                        <option key={unit._id} value={unit.name}>
                                                                            {unit.name}
                                                                        </option>
                                                                    ))}</>

                                                            }

                                                        </select>
                                                    </div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='col-sm-12'>
                                    <div className='card'>
                                        <div className="card-header">
                                            <h5>Vehicle Details</h5>
                                        </div>
                                        <div className='card-body'>
                                            <div className='container'>
                                                <div className='row'>

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label>Vehicle Reg Number</label>
                                                            <input className="form-control mt-2" type="text" placeholder="Vehicle reg no"
                                                                value={carRegistration} onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    setCarRegistration(value)
                                                                }}
                                                            ></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Vehicle Model</label>
                                                            <input
                                                                type="text"
                                                                className="mb-3 form-control"
                                                                value={carMake}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setCarMake(value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Vehicle Color</label>
                                                            <input
                                                                type="text"
                                                                className="mb-3 form-control"
                                                                value={carColor}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setCarColor(value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">No of Occupants</label>
                                                            <input
                                                                type="text"
                                                                className="mb-3 form-control"
                                                                value={carOccupants}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    setCarOccupants(value)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>


                                                  

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <input type="checkbox" checked={confirmVisit} value={confirmVisit} onChange={(e) => {

                                                setConfirmVisit(!confirmVisit)
                                            }} style={{ padding: 20 }} /> &nbsp;
                                            <label className="form-label">Send a Confirmation of Visit to Resident</label>

                                        </div>
                                    </div>

                                </div>

                                <div className="text-end mt-2">
                                    <button type="button" className="btn btn-outline-secondary" onClick={handleClear}>Clear</button>
                                    &nbsp;
                                    <button type="button" className="btn btn-primary" onClick={handleVisitorSubmit}>Submit</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </Layout >
    )
}


export default GateRegistration